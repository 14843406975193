define("discourse/plugins/discourse-category-tokenization/discourse/components/modal/control-message", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @title={{i18n "topics.errors.not_enough_tokens_title"}} @closeModal={{@closeModal}}>
    <div class="your-modal">
      <div class="message">{{i18n "topics.errors.not_enough_tokens"}}</div>
      <div class="modal-footer">
        <DButton @label={{ "actions.accept" }} class="btn-primary send-invite" @action={{@closeModal}}
        />
      </div>
    </div>
  </DModal>
  
  */
  {
    "id": "6iohXfTm",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"topics.errors.not_enough_tokens_title\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"your-modal\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"message\"],[12],[1,[28,[35,1],[\"topics.errors.not_enough_tokens\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"btn-primary send-invite\"]],[[\"@label\",\"@action\"],[\"actions.accept\",[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-category-tokenization/discourse/components/modal/control-message.hbs",
    "isStrictMode": false
  });
  let ControlMessageModal = _exports.default = (_class = class ControlMessageModal extends _component2.default {
    cancel() {
      // Close the modal here
      this.args.closeModal();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ControlMessageModal);
});